import React, { useMemo, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Container,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationType, State } from '../../shared/interface';
import httpService from '../../shared/services/http.service';
import { API_CONFIG } from '../../shared/constants/constants';
import { fetchCompanyDetails } from '../../store/actions/authActions';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CountrySelect from '../../shared/components/country-select';
import { DropzoneDialogBase } from 'material-ui-dropzone';
import actionTypes from '../../store/action-types';
import { currencyOptions } from '../dashboard/event/create';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const FilePreview = ({
  currentFileUrl,
  newFileUrl
}: {
  currentFileUrl: string | null;
  newFileUrl: File | null;
}) => {
  if (!(currentFileUrl || newFileUrl)) {
    return null;
  }

  return (
    <i className="py-2">
      {currentFileUrl?.split('/').at(-1) || newFileUrl?.name}
      {currentFileUrl ? (
        <IconButton
          onClick={() => {
            window.open(currentFileUrl, '_blank');
          }}
          size="small"
          aria-label="open link"
          style={{ marginLeft: 8 }}
        >
          <OpenInNewIcon />
        </IconButton>
      ) : null}
    </i>
  );
};

interface ICompanyProfile {
  name: string;
  currency: string;
  location: string;
  support_number: string;
  country: string;
  business_email: string;
  company_document_one: File | null;
  company_document_two: File | null;
  company_document_three: File | null;
  national_id: File | null;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  currency: Yup.string().required('Currency is required'),
  location: Yup.string().required('Location is required'),
  support_number: Yup.string().required('Support number is required'),
  country: Yup.string().required('Country is required'),
  business_email: Yup.string()
    .email('Invalid email address')
    .required('Business email is required'),
  company_document_one: Yup.mixed(),
  company_document_two: Yup.mixed(),
  company_document_three: Yup.mixed(),
  national_id: Yup.mixed()
});

const CompanyProfile: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: State) => state.auth.user);
  const company = useSelector((state: State) => state.auth.user.company);
  const [selectedFiles, setSelectedFiles] = useState<{ file: File }[]>([]);
  const [uploadDialog, setUploadDialog] = React.useState({
    open: false,
    title: 'Upload National ID',
    fileLimit: 1,
    isNationalId: true
  });

  const initialValues: ICompanyProfile = useMemo(
    () => ({
      name: company?.name || '',
      location: company?.location || '',
      support_number: company.support_number || '',
      country: company.country || '',
      business_email: company.business_email || '',
      company_document_one: null,
      company_document_two: null,
      company_document_three: null,
      national_id: null,
      currency: company.currency || 'UGX'
    }),
    [company]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      try {
        const formData = new FormData();
        // Check for updated fields and append only those to formData
        (Object.keys(values) as (keyof ICompanyProfile)[]).forEach(key => {
          if (user.hasCompany) {
            if (values[key] !== initialValues[key]) {
              formData.append(key, values[key] || '');
            }
          } else {
            formData.append(key, values[key] || '');
          }
        });

        await httpService[user.hasCompany ? 'patch' : 'post'](
          API_CONFIG.PATH.COMPANY[user.hasCompany ? 'UPDATE' : 'CREATE'],
          formData,
          {},
          { contentType: 'multipart/form-data' }
        )(dispatch, true);

        await fetchCompanyDetails(dispatch);

        dispatch({
          type: actionTypes.SET_NOTIFICATION,
          payload: {
            message: `Company was ${
              user.hasCompany ? 'updated' : 'created'
            } successfully`,
            type: NotificationType.SUCCESS
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  });

  return (
    <Container className="h-full" maxWidth={false}>
      {!user.hasCompany && (
        <Alert variant="outlined" severity="info">
          Please complete your company profile
        </Alert>
      )}
      <Typography variant="h6" className="mt-4">
        Company Profile
      </Typography>
      <form
        onSubmit={formik.handleSubmit}
        className="h-full flex flex-col mt-5"
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FormLabel>Company Name</FormLabel>
            <TextField
              variant="outlined"
              name="name"
              placeholder="Company Name"
              InputProps={{
                readOnly: !!company?.name
              }}
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel>Business Email</FormLabel>
            <TextField
              name="business_email"
              placeholder="Business Email"
              fullWidth
              value={formik.values.business_email}
              onChange={formik.handleChange}
              error={
                formik.touched.business_email &&
                Boolean(formik.errors.business_email)
              }
              helperText={
                formik.touched.business_email && formik.errors.business_email
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel>Country</FormLabel>
            <CountrySelect
              name="coutry"
              placeholder="Select a country"
              value={formik.values.country}
              handleOptionSelected={value => {
                if (value) {
                  formik.setFieldValue('country', value.code);
                }
              }}
            />
            {formik.touched.country && formik.errors.country && (
              <div>{formik.errors.country}</div>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel>Location</FormLabel>
            <TextField
              name="location"
              placeholder="City, street, village"
              fullWidth
              value={formik.values.location}
              onChange={formik.handleChange}
              error={formik.touched.location && Boolean(formik.errors.location)}
              helperText={formik.touched.location && formik.errors.location}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel>Currency</FormLabel>
            <Select
              name="currency"
              placeholder="Currency"
              value={formik.values.currency}
              onChange={formik.handleChange}
              fullWidth
              readOnly
              error={formik.touched.currency && Boolean(formik.errors.currency)}
            >
              {currencyOptions?.map(
                (eventType: { value: string; label: string }) => (
                  <MenuItem key={eventType.value} value={eventType.value}>
                    {eventType.label}
                  </MenuItem>
                )
              )}
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel>Support Number</FormLabel>
            <PhoneInput
              country={formik.values.country.toLowerCase() || 'us'}
              value={formik.values.support_number}
              onChange={(value: string) =>
                formik.setFieldValue('support_number', value)
              }
            />
            {formik.touched.support_number && formik.errors.support_number && (
              <div>{formik.errors.support_number}</div>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <FormLabel className="mr-4">National ID</FormLabel>
            <Button
              className="capitalize pa-0"
              variant="outlined"
              size="small"
              color="primary"
              onClick={() =>
                setUploadDialog({
                  open: true,
                  title: 'Upload National ID',
                  fileLimit: 1,
                  isNationalId: true
                })
              }
            >
              {company?.national_id ? 'Replace ' : 'Select '} File
            </Button>
            <Grid item xs={6} className="px-4">
              <FilePreview
                currentFileUrl={company?.national_id}
                newFileUrl={formik.values.national_id}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormLabel className="mr-4">
              Provide company documents (Maximum 3)
            </FormLabel>
            <Button
              size="small"
              variant="outlined"
              className="ml-4 pa-1 capitalize"
              color="primary"
              onClick={() =>
                setUploadDialog({
                  open: true,
                  title: 'Upload company files (Maximum 3)',
                  fileLimit: 3,
                  isNationalId: false
                })
              }
            >
              {company?.company_document_one &&
              company?.company_document_two &&
              company?.company_document_three
                ? 'Replace '
                : 'Select '}
              Files
            </Button>
            <Grid item xs={6} className="px-4">
              <FilePreview
                currentFileUrl={company?.company_document_one}
                newFileUrl={formik.values.company_document_one}
              />
              <br />
              <FilePreview
                currentFileUrl={company?.company_document_two}
                newFileUrl={formik.values.company_document_two}
              />
              <br />
              <FilePreview
                currentFileUrl={company?.company_document_three}
                newFileUrl={formik.values.company_document_three}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} className="px-4">
          <DropzoneDialogBase
            dialogTitle={uploadDialog.title}
            acceptedFiles={['image/*', 'application/pdf']}
            maxFileSize={5000000}
            filesLimit={uploadDialog.fileLimit}
            fileObjects={selectedFiles}
            cancelButtonText={'cancel'}
            submitButtonText={'submit'}
            open={uploadDialog.open}
            useChipsForPreview
            onAdd={(newFileObjs: any[]) => {
              setSelectedFiles([...selectedFiles, ...newFileObjs]);
            }}
            onDelete={(deleteFileObj: any) => {
              setSelectedFiles(prevState =>
                prevState.filter(item => item !== deleteFileObj)
              );
            }}
            onClose={() =>
              setUploadDialog({
                open: false,
                title: 'Upload National ID',
                fileLimit: 1,
                isNationalId: true
              })
            }
            onSave={() => {
              if (uploadDialog.isNationalId) {
                formik.setFieldValue('national_id', selectedFiles[0]?.file);
              } else {
                formik.setValues({
                  ...formik.values,
                  company_document_one: selectedFiles[0]?.file,
                  company_document_two: selectedFiles[1]?.file,
                  company_document_three: selectedFiles[2]?.file
                });
              }
              setSelectedFiles([]);
              setUploadDialog({
                open: false,
                title: 'Upload National ID',
                fileLimit: 1,
                isNationalId: true
              });
            }}
            showPreviews={true}
            showFileNamesInPreview={true}
          />
        </Grid>
        <Box className="flex flex-row justify-end mt-5">
          <Button
            disabled={!formik.dirty}
            size="small"
            variant="contained"
            className="capitalize"
            type="submit"
          >
            {user.hasCompany ? 'Update Details' : 'Save Details'}
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default CompanyProfile;
